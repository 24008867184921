<template>
  <div class="news" :data-page="modalParams.page" :class="{skeleton: !state.loaded.news}">
    <div class="header">
      <div class="top">
        <div class="title font-md">
          <b>{{ state.news.title }}</b>
        </div>
        <div class="acts">
          <template v-if="modalParams.page === 'manage'">
            <button class="btn btn-default border font-sm edit" @click="edit()">
              <i class="fa fa-pencil"></i>
            </button>
            <button class="btn btn-default border font-sm remove" @click="remove()">
              <i class="fa fa-trash"></i>
            </button>
          </template>
          <router-link class="font-sm" :to="`/${modalParams.projectType}/${modalParams.projectSeq}`" v-else-if="modalParams.page !== 'detail'">프로젝트로 이동</router-link>
        </div>
      </div>
      <div class="desc font-sm">
        <router-link :to="`/channel/${state.news.writerMemberSeq}`">{{ state.news.writerName }}</router-link>
        <span class="float-right">{{ state.news.createDate }}</span>
      </div>
    </div>
    <div class="wrapper">
      <div class="content edit-style font-sm" v-html="state.news.content"></div>
      <div class="maker-info">
        <MakerInfo :builderSeq="state.news.writerMemberSeq" :projectType="modalParams.projectType"/>
      </div>
      <div class="comment" :class="{skeleton:!state.loaded.comments}">
        <div class="form">
          <div class="title">
            <span>댓글 작성</span>
          </div>
          <div class="input">
          <textarea class="form-control border-focus-point" :placeholder="$store.state.account.loggedIn ? '댓글을 남겨주세요.' : $definitions.messages.needLogin" :readonly="!$store.state.account.loggedIn" @focus="checkLogin()"
                    ref="commentTextareaRef"></textarea>
            <button class="btn btn-secondary border font-sm" @click="postComment()" :disabled="!$store.state.account.loggedIn">등록</button>
          </div>
        </div>
        <div class="list" v-if="Array.isArray(state.comments) && state.comments.length">
          <ul class="tight">
            <li class="font-sm" :class="{me: c.memberSeq && c.memberSeq === $store.state.account.memberSeq}" v-for="(c, idx) in state.comments" :key="idx" :data-depth="c.depth">
              <div class="wrapper">
                <MemberPicture :memberSeq="c.createId" size="35" :skeleton="!state.loaded"/>
                <div class="root">
                  <b class="mr-1">{{ c.memberName }}</b>
                  <br class="d-block d-sm-none"/>
                  <span class="font-xs mr-1" v-if="modalParams.projectType === 'reward' && modalParams.page === 'manage'">({{ c.memberId }})</span>
                  <span class="font-xs">{{ c.createDate }}</span>
                  <div class="actions">
                    <button class="btn btn-default" @click="editComment(c)">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-default" @click="removeComment(c.commentSeq)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
                <div class="content">
                  <span v-html="c.content"></span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="action" v-if="modalParams.page === 'my'">
      <router-link :to="`/${modalParams.projectType === 'mock' ? 'reward' : modalParams.projectType}/${modalParams.projectSeq}`" class="btn btn-point btn-block font-sm">
        <span>프로젝트로 이동</span>
        <span class="img" style="background-image:url(/assets/ico/common.join.white.svg)"></span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import MemberPicture from "@/components/MemberPicture";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import MakerInfo from "@/components/MakerInfo";

function Component(initialize) {
  this.name = "modalNews";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MakerInfo, MemberPicture},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xm"});
      loadNews();
      loadComments();
    });

    const state = reactive({
      loaded: {
        news: false,
        comments: false
      },
      news: {
        title: "Wait a moment",
        content: "Please wait a moment",
        writerMemberSeq: "",
        writerName: "Wait",
        createDate: "0000-00-00 00:00"
      },
      comments: []
    });

    const commentTextareaRef = ref();
    const modalParams = store.getters.modalParams(component);

    const edit = () => {
      store.commit("replaceModal", {
        name: "NewsWrite",
        params: modalParams,
        callback: "refresh"
      });
    };

    const remove = () => {
      store.commit("confirm", {
        message: "삭제하시겠습니까?",
        allow() {
          http.delete(`/api/maker/project/${modalParams.projectType}/${modalParams.projectSeq}/news/${modalParams.newsSeq}`).then(() => {
            store.commit("closeModal", {
              name: component.name,
              onClose(modal) {
                store.dispatch("callback", {modal});
                store.commit("setSwingMessage", "삭제하였습니다.");
              }
            });
          });
        }
      });
    };

    const checkLogin = () => {
      !store.state.account.memberSeq && store.dispatch("goLoginPage");
    };

    const loadNews = () => {
      state.loaded.news = false;
      http.get(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news/${modalParams.newsSeq}`).then(({data}) => {
        state.loaded.news = true;
        state.news = data.body;

        // if (state.news.content) {
        //   state.news.content = lib.getUrlToAnchorReplaced(state.news.content);
        // }
      });
    };

    const loadComments = () => {
      state.comments = [];

      for (let i = 0; i < 3; i += 1) {
        state.comments.push({
          memberName: "Wait a moment",
          createDate: "0000-00-00 00:00",
          content: "Wait a moment",
        });
      }

      state.loaded.comments = false;
      http.get(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news-comments`, {newsSeq: modalParams.newsSeq}).then(({data}) => {
        state.loaded.comments = true;
        state.comments = data.body;
      });
    };

    const postComment = () => {
      const content = commentTextareaRef.value.value;

      if (!content?.trim()) {
        store.commit("setSwingMessage", "내용을 입력해주세요.");
        commentTextareaRef.value.focus();
        return;
      }

      const args = {
        newsSeq: modalParams.newsSeq,
        content: content,
        createId: store.state.account.memberSeq
      };

      http.post(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news-comments`, args).then(() => {
        store.dispatch("callback", {component});
        store.commit("setSwingMessage", "댓글을 등록하였습니다.");
        loadComments();
        commentTextareaRef.value.value = "";
      });
    };

    const patchComment = ({commentSeq, content, updateId}) => {
      http.patch(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news-comments/${commentSeq}`, {content, updateId}).then(() => {
        store.commit("setSwingMessage", "댓글을 수정하였습니다.");
        loadComments();
      }).catch(httpError());
    };

    const editComment = (comment) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          commentSeq: comment.commentSeq,
          updateId: comment.memberSeq,
          content: comment.content
        },
        callback: `${component.name}.patchComment`,
      });
    };

    const removeComment = (commentSeq) => {
      store.commit("confirm", {
        message: "선택하신 댓글을 삭제하시겠습니까?",
        allow() {
          const args = {
            updateId: store.state.account.memberSeq
          };

          http.delete(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news-comments/${commentSeq}`, args).then(() => {
            store.dispatch("callback", {component});
            store.commit("setSwingMessage", "댓글을 삭제하였습니다.");
            loadComments();
          });
        }
      });
    };

    return {component, state, commentTextareaRef, modalParams, edit, remove, checkLogin, postComment, patchComment, editComment, removeComment};
  },
});
</script>

<style lang="scss" scoped>
.news {
  > .header {
    background: #fff;
    padding: $px35 $px40;
    border-bottom: 1px solid #eee;

    > .top {
      position: relative;
      padding-right: $px100;

      > .title {
        margin-bottom: $px25;
      }

      > .acts {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        > .btn {
          padding: $px12 $px15;
          margin-left: $px4;
          margin-right: 0;
          border: 0;
        }
      }
    }

    > .desc {
      color: #666;
    }
  }

  > .wrapper {
    background: #fff;
    color: #000;
    padding: $px30 $px25;

    > .content {
      padding: 0 $px15;

      &::v-deep {
        img {
          height: auto;
        }
      }
    }

    .maker-info {
      margin: $px60 auto 0 auto;
    }

    .comment {
      margin-top: $px25;
      padding-top: $px25;

      .form {
        > .title {
          margin-bottom: $px15;
        }

        > .input {
          padding-right: $px115;
          position: relative;

          textarea {
            max-width: 100%;
            min-width: 100%;
            min-height: $px100;
            font-size: $px14;
            padding: $px13;
            border-color: #ddd;
            resize: none;
          }

          button {
            position: absolute;
            right: 0;
            top: 0;
            width: $px100;
            height: $px100;
          }
        }
      }

      .list {
        margin-top: $px35;
        margin-bottom: $px5;

        li {
          padding-bottom: $px30;

          > .wrapper {
            position: relative;
            padding-left: $px47;

            .img {
              width: $px35;
              height: $px35;
              position: absolute;
              top: $px3;
              left: 0;
              border: $px1 solid #eee;
              border-radius: 50%;
            }

            .root {
              padding-right: $px114;

              .actions {
                display: none;
                position: absolute;
                top: 0;
                right: 0;

                .btn {
                  margin-left: $px4;
                  padding: $px12 $px17;
                  margin-right: 0;
                }
              }
            }

            .content {
              margin-top: $px5;
              word-break: break-all;

              > span {
                white-space: pre-line;
              }
            }

            ul {
              margin-top: $px30;

              li {
                &:before {
                  content: " ";
                  background-image: url(/assets/img/page.project.detail.comment.pipe.svg);
                  background-size: 100% 100%;
                  width: $px20;
                  height: $px20;
                  position: absolute;
                  top: $px3;
                  left: $px-35;
                }
              }
            }
          }

          &:last-child {
            padding-bottom: 0;
          }

          &[data-depth="1"] {
            padding-left: $px47;
            position: relative;

            &:before {
              content: " ";
              background-image: url(/assets/img/page.project.detail.comment.pipe.svg);
              background-size: 100% 100%;
              width: $px20;
              height: $px20;
              position: absolute;
              top: $px5;
              left: $px15;
            }
          }

          &.me {
            > .wrapper {
              .root .actions {
                display: block;
              }
            }
          }
        }
      }

      .page {
        margin-top: $px25;
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      border-radius: 0;

      span {
        vertical-align: middle;

        &.img {
          width: $px24;
          height: $px24;
          margin-left: $px8;
        }
      }

      i {
        margin-right: $px8;
        vertical-align: middle;
      }
    }
  }

  &[data-page=manage] {
    > .wrapper > .top {
      padding-right: $px140;
    }
  }

  &.skeleton {
    .header {
      .title > b {
        @include skeleton;
      }

      .acts a {
        @include skeleton;
      }

      .desc {
        > a, > span {
          @include skeleton;
        }
      }
    }

    > .wrapper {
      .acts {
        .btn, a {
          @include skeleton;
        }
      }

      .content {
        @include skeleton;
      }
    }

    .action .btn {
      @include skeleton;

      .img {
        visibility: hidden;
      }
    }
  }

  > .wrapper .comment.skeleton {
    .form > .title > span {
      @include skeleton;
    }

    .input {
      textarea,
      .btn {
        @include skeleton;
      }
    }

    .list li {
      .img,
      .root b,
      .root > span,
      .root .actions .btn,
      .content > span {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    > .wrapper {
      padding: $px15;

      .comment {
        padding: 0 0 $px35;
      }
    }
  }

  @media(max-width: 767px) {
    > .wrapper .comment .list li.me > .wrapper .root {
      padding-right: $px100;

      .actions .btn {
        padding: $px7 $px12;
      }
    }
  }
}
</style>